<template>
  <section id="projects">
    <h2 class="section-title">Featured Projects</h2>
    <div class="projects-grid">
      <div
        v-for="project in projects"
        :key="project.title"
        class="project-card"
      >
        <h3>{{ project.title }}</h3>
        <p>{{ project.description }}</p>
        <div class="skills-container">
          <span
            v-for="tech in project.technologies"
            :key="tech"
            class="skill-tag"
          >
            {{ tech }}
          </span>
          <p v-if="project.url || project.liveUrl" class="project-link">
            <a
              v-if="project.url"
              :href="project.url"
              target="_blank"
              rel="noopener noreferrer"
              class="view-button"
            >
              View on GitHub
            </a>
            <a
              v-if="project.liveUrl"
              :href="project.liveUrl"
              target="_blank"
              rel="noopener noreferrer"
              class="live-button"
            >
              Try Live
            </a>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProjectsSection",
  props: {
    projects: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.section-title {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.project-card {
  background: #1e293b;
  border-radius: 1rem;
  padding: 1.5rem;
  transition: transform 0.3s;
  cursor: pointer;
}

.project-card:hover {
  transform: translateY(-5px);
}

.project-card h3 {
  color: #60a5fa;
  margin-bottom: 1rem;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 2rem;
}

.skill-tag {
  background: #2563eb;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.9rem;
}

.project-link {
  text-align: center;
}

.view-button {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #ffffff; /* White background for contrast */
  color: #1e3a8a; /* Dark blue text to complement blue background */
  border: 2px solid #1e3a8a; /* Border to match text color */
  border-radius: 0.5rem;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.view-button:hover {
  background-color: #1e3a8a; /* Dark blue background on hover */
  color: #ffffff; /* White text on hover */
}

.live-button {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #34d399; /* Green background for contrast */
  color: #064e3b; /* Dark green text */
  border: 2px solid #064e3b;
  border-radius: 0.5rem;
  text-decoration: none;
  font-weight: bold;
  margin-left: 10px;
  transition: background-color 0.3s, color 0.3s;
}

.live-button:hover {
  background-color: #064e3b; /* Dark green on hover */
  color: #ffffff; /* White text */
}
</style>