<template>
  <div>
    <AnimatedBackground />

    <nav>
      <div class="nav-content">
        <div class="logo">{{ portfolio.name.split(" ")[0] }}</div>
        <div
          class="nav-links"
          :class="{ active: showMenu, closing: isClosing }"
          @animationend="onAnimationEnd"
        >
          <a href="#about" @click="closeMenu">About</a>
          <a href="#projects" @click="closeMenu">Projects</a>
          <a href="#skills" @click="closeMenu">Skills</a>
          <a href="#contact" @click="closeMenu">Contact</a>
        </div>
        <div
          class="hamburger"
          @click="toggleMenu"
          :class="{ 'is-active': showMenu }"
        >
          <span class="line"></span>
          <span class="line"></span>
          <span class="line"></span>
        </div>
      </div>
    </nav>

    <div class="container">
      <Hero
        :name="portfolio.name"
        :titles="portfolio.titles"
        :bio="portfolio.bio"
        :social-links="portfolio.socialLinks"
      />

      <Projects :projects="portfolio.projects" />

      <section id="skills">
        <h2 class="section-title">Skills & Technologies</h2>
        <div class="skills-container">
          <span
            v-for="skill in portfolio.skills"
            :key="skill"
            class="skill-tag"
          >
            {{ skill }}
          </span>
        </div>
      </section>

      <footer id="contact">
        <div class="footer-content">
          <h2 class="section-title">Let's Connect</h2>
          <p class="footer-text">
            I'm always open to new opportunities and collaborations.
          </p>
          <p class="footer-contact">
            &copy; 2024 Tadas Šolys |
            <a href="mailto:tadassolys@gmail.com">Contact Me</a>
          </p>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import Hero from "./components/Hero.vue";
import Projects from "./components/Projects.vue";
import AnimatedBackground from "./components/AnimatedBackground.vue";
import { ref } from "vue";

export default {
  name: "App",
  components: {
    Hero,
    Projects,
    AnimatedBackground,
  },
  setup() {
    const portfolio = {
      name: "Tadas Šolys",
      titles: [
        "Full Stack Developer",
        "Android Developer",
        "AI training Enthusiast",
      ],
      bio: "Hi, I'm Tadas Šolys, a recent graduate with a Bachelor's degree in Software Development. I'm passionate about coding, solving problems, and building innovative software solutions. Below are some of the projects I’ve worked on, feel free to check them out!",
      socialLinks: [
        { name: "GitHub", url: "https://github.com/tadassolys", icon: "📦" },
        {
          name: "LinkedIn",
          url: "https://www.linkedin.com/in/tadas-%C5%A0olys-8a2418328/",
          icon: "💼",
        },
      ],
      projects: [
        {
          title: "TextScan - OCR Screenshot Tool",
          description:
            "TextScan is a lightweight Windows desktop application that enables users to capture screenshots of any area on their screen and extract text using Optical Character Recognition (OCR) technology. It uses the Tesseract OCR engine for text recognition and offers a simple, user-friendly interface with system tray integration for easy access.",
          technologies: ["C#","Tesseract OCR", "System.Drawing", "Windows Forms", "Visual Studio"],
          url: "https://github.com/tadassolys/TextScan",
        },
        {
          title: "Movie Search Application",
          description:
            "This Spring Boot application enables users to search for movies by querying a local PostgreSQL database with IMDb data or fetching and enhancing movie data from the TMDb and OMDb APIs, utilizing JPA for database interaction and Thymeleaf for templating.",
          technologies: ["Java", "Html", "CSS", "JavaScript", "Docker Compose"],
          url: "https://github.com/tadassolys/PopcornScout_Public",
          liveUrl: "https://popcorn.toshibadatacenter.site/",
        },
        {
          title: "Landmark Recognition",
          description:
            "Android application that recognizes landmarks using machine learning.",
          technologies: ["Kotlin", "Python", "TensorFlow", "Machine Learning"],
          url: "https://github.com/tadassolys/Lankytinu_objektu_atpazinimas",
        },
        {
          title: "A fullstack Reservation Management System",
          description:
            "Real-time room, equipment, and vehicle reservation system.",
          technologies: ["Vue 3", "Laravel", "MySQL"],
          url: "https://github.com/tadassolys/Rezervis_frontend",
          liveUrl: "https://tadassolys.free.nf/",
        },
        {
          title: "Android password manager",
          description:
            "Android application that uses SQLCipher for encryption, allowing users to manage their credentials safely.",
          technologies: ["Java", "SQL Lite"],
          url: "https://github.com/tadassolys/Passman",
        },
        {
          title: "Android document scanner",
          description:
            "Android application that utilizes Google ML Kit to scan documents using your device camera. The scanned documents can be saved as PDF files in a designated folder within the devices Documents directory.",
          technologies: ["Kotlin"],
          url: "https://github.com/tadassolys/DokScanas",
        },
        {
          title: "Points Calculator",
          description:
            "A React-based scorekeeping application that allows users to track points for different games, customize scoring increments, and save game history. Supports dark mode, player management, and automatic game statistics tracking.",
          technologies: [
            "React",
            "Lucide Icons",
            "LocalStorage",
            "Tailwind CSS",
          ],
          url: "https://github.com/tadassolys/points-tracker.git",
          liveUrl: "https://points-tracker-two.vercel.app/",
        },
        {
          title: "ScoreKeeper: Table Game Score Tracker",
          description:
            "Android application designed to simplify score tracking for table games. Whether you are playing board games, card games, or any other table-based activity that requires scorekeeping, ScoreKeeper has you covered.",
          technologies: ["Java"],
          url: "https://github.com/tadassolys/Taskainis",
        },
      ],
      skills: [
        "Java",
        "Kotlin",
        "C#",
        "JavaScript",
        "Node.js",
        "SpringBoot",
        "Laravel",
        "MySQL",
        "PostgreSQL",
        "Docker",
        "Git",
        "REST APIs",
        "Postman"
      ],
    };

    const showMenu = ref(false);
    const isClosing = ref(false);

    const toggleMenu = () => {
      showMenu.value = !showMenu.value;
      isClosing.value = !showMenu.value;
    };

    const closeMenu = () => {
      isClosing.value = true;
    };

    const onAnimationEnd = () => {
      if (isClosing.value) {
        showMenu.value = false;
        isClosing.value = false;
      }
    };

    return {
      portfolio,
      showMenu,
      toggleMenu,
      closeMenu,
      onAnimationEnd,
      isClosing,
    };
  },
  metaInfo() {
    return {
      title: "Tadas Šolys - Full Stack Developer Portfolio",
      meta: [
        {
          name: "description",
          content:
            "Explore Tadas Šolys's portfolio showcasing projects, skills, and expertise in full-stack development, Android, and AI training.",
        },
        {
          name: "keywords",
          content:
            "Tadas Šolys, Full Stack Developer, Android Developer, AI Enthusiast, Software Portfolio",
        },
        { name: "author", content: "Tadas Šolys" },
        {
          property: "og:title",
          content: "Tadas Šolys - Full Stack Developer Portfolio",
        },
        {
          property: "og:description",
          content:
            "Discover Tadas Šolys's projects and skills in full-stack development and AI.",
        },
        {
          property: "og:image",
          content:
            "https://lh3.googleusercontent.com/a/ACg8ocIr5x2zDxzkJB61l9_5IsNzeHBG3ljQFuUfxCK8NVoU9psbnYb_sA=s360-c-no",
        },
        { property: "og:url", content: "https://tadassolys.site" },
        { property: "og:type", content: "website" },
        { name: "twitter:card", content: "summary_large_image" },
        {
          name: "twitter:title",
          content: "Tadas Šolys - Full Stack Developer Portfolio",
        },
        {
          name: "twitter:description",
          content:
            "Discover Tadas Šolys's projects and skills in full-stack development and AI.",
        },
        {
          name: "twitter:image",
          content:
            "https://lh3.googleusercontent.com/a/ACg8ocIr5x2zDxzkJB61l9_5IsNzeHBG3ljQFuUfxCK8NVoU9psbnYb_sA=s360-c-no",
        },
        // Pridedame kanoninę žymą
        { rel: "canonical", href: "https://tadassolys.site" },
      ],
      script: [
        {
          type: "application/ld+json",
          innerHTML: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Person",
            name: "Tadas Šolys",
            jobTitle: "Full Stack Developer",
            url: "https://tadassolys.site",
            sameAs: [
              "https://github.com/tadassolys",
              "https://www.linkedin.com/in/tadas-%C5%A0olys-8a2418328/",
            ],
            image:
              "https://lh3.googleusercontent.com/a/ACg8ocIr5x2zDxzkJB61l9_5IsNzeHBG3ljQFuUfxCK8NVoU9psbnYb_sA=s360-c-no", // Replace with your image URL
            description:
              "Portfolio showcasing Tadas Šolys's projects and expertise in full-stack development, Android, and AI training.",
            alumniOf: { "@type": "CollegeOrUniversity", name: "SMK, MRU" },
            knowsAbout: [
              "Java",
              "Kotlin",
              "JavaScript",
              "Node.js",
              "SpringBoot",
              "Laravel",
              "MySQL",
              "Docker",
              "AI",
              "Machine Learning",
            ],
            workExample: [
              {
                "@type": "CreativeWork",
                headline: "Landmark Recognition Android App",
                description:
                  "Android app for landmark recognition using machine learning.",
                creator: "Tadas Šolys",
                programmingLanguage: ["Kotlin", "Python", "TensorFlow"],
                url: "https://github.com/tadassolys/Lankytinu_objektu_atpazinimas",
              },
              {
                "@type": "CreativeWork",
                headline: "Reservation Management System",
                description:
                  "Fullstack system for real-time reservations of rooms, equipment, and vehicles.",
                creator: "Tadas Šolys",
                programmingLanguage: ["Vue 3", "Laravel", "MySQL"],
                url: "https://github.com/tadassolys/Rezervis_frontend",
              },
            ],
          }),
        },
      ],
    };
  },
};
</script>



<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, sans-serif;
}

body {
  background: #0f172a;
  color: #e2e8f0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  position: relative; /* Ensures content stays on top of the background */
  z-index: 10; /* Ensures content stays above the background */
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  position: fixed;
  top: 0;
  width: 100%;
  background: rgba(15, 23, 42, 0.9);
  backdrop-filter: blur(10px);
  z-index: 100;
}

.nav-content {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #60a5fa;
}

.nav-links {
  display: flex;
  gap: 2rem;
}

.nav-links a {
  color: #e2e8f0;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-links a:hover {
  color: #60a5fa;
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  cursor: pointer;
  gap: 5px;
}

.hamburger .bar {
  height: 3px;
  background-color: #e2e8f0;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .nav-links.active {
    display: block;
  }

  .hamburger {
    display: flex;
  }
}

.section-title {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

#skills {
  margin: 4rem 0;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 2rem;
}

.skill-tag {
  background: #2563eb;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.9rem;
}

#contact {
  margin: 4rem 0;
}

.contact-form {
  max-width: 500px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid #4b5563;
  background: #1e293b;
  color: #e2e8f0;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #60a5fa;
}

button {
  background: #2563eb;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background 0.3s;
}

button:hover {
  background: #1d4ed8;
}

/* Footer Styling */
#contact {
  color: #b3b3b3;
  padding: 2rem 1.5rem;
  text-align: center;
}

.footer-content {
  max-width: 600px;
  margin: 0 auto;
}

.section-title {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #f5f5f5;
}

.footer-text {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #b3b3b3;
}

.footer-contact {
  font-size: 0.875rem;
  color: #7f7f7f;
}

footer a {
  color: #0078ff;
  text-decoration: none;
  transition: color 0.3s ease;
}

footer a:hover {
  color: #005fcc;
}

/* Projects Section */
.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.project-card {
  background: #1e293b;
  border-radius: 1rem;
  padding: 1.5rem;
  transition: transform 0.3s;
  cursor: pointer;
}

.project-card:hover {
  transform: translateY(-10px);
}

.project-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.project-description {
  margin: 1rem 0;
}

.project-card ul {
  list-style-type: none;
  padding-left: 0;
}

.project-card li {
  background: #2563eb;
  color: #fff;
  padding: 0.3rem 0.6rem;
  margin: 0.3rem 0;
  border-radius: 0.5rem;
}

.hamburger {
  display: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: relative;
  transition: all 0.25s;
}

.line {
  position: absolute;
  height: 2px;
  width: 100%;
  background: #e2e8f0;
  border-radius: 4px;
  transition: all 0.5s ease;
  left: 0;
}

.line:nth-child(1) {
  top: 0;
}

.line:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.line:nth-child(3) {
  bottom: 0;
}

/* Animated state */
.hamburger.is-active .line:nth-child(1) {
  transform: translateY(11px) rotate(45deg);
}

.hamburger.is-active .line:nth-child(2) {
  opacity: 0;
}

.hamburger.is-active .line:nth-child(3) {
  transform: translateY(-11px) rotate(-45deg);
}

@media (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .nav-links {
    display: none;
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    background: rgba(15, 23, 42, 0.95);
    backdrop-filter: blur(10px);
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .nav-links.active {
    display: flex;
  }
}

/* Optional hover effect */
.hamburger:hover .line {
  background: #60a5fa;
}
.hamburger {
  display: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: relative;
  transition: all 0.25s;
}

.line {
  position: absolute;
  height: 2px;
  width: 100%;
  background: #e2e8f0;
  border-radius: 4px;
  transition: all 0.5s ease;
  left: 0;
}

.line:nth-child(1) {
  top: 0;
}

.line:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.line:nth-child(3) {
  bottom: 0;
}

/* Animated state */
.hamburger.is-active .line:nth-child(1) {
  transform: translateY(11px) rotate(45deg);
}

.hamburger.is-active .line:nth-child(2) {
  opacity: 0;
}

.hamburger.is-active .line:nth-child(3) {
  transform: translateY(-11px) rotate(-45deg);
}

@media (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .nav-links {
    display: none;
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    background: rgba(15, 23, 42, 0.95);
    backdrop-filter: blur(10px);
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .nav-links.active {
    display: flex;
    animation: slideDown 0.3s ease forwards;
  }

  .nav-links.closing {
    animation: slideUp 0.3s ease forwards;
  }
}

/* Hover effect */
.hamburger:hover .line {
  background: #60a5fa;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

/* Transition for links */
.nav-links a {
  transition: transform 0.2s ease;
}

.nav-links a:hover {
  transform: translateY(-2px);
}

/* Staggered animation for menu items */
.nav-links.active a {
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

.nav-links.active a:nth-child(1) {
  animation-delay: 0.1s;
}
.nav-links.active a:nth-child(2) {
  animation-delay: 0.2s;
}
.nav-links.active a:nth-child(3) {
  animation-delay: 0.3s;
}
.nav-links.active a:nth-child(4) {
  animation-delay: 0.4s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>

